import { request } from "../request";

// 添加评论
export function addComment(data) {
  return request({
    url: `/v1/comment`,
    method: "post",
    data,
  });
}

// 查看新闻的评论
export function getCommentsList(id) {
  return request({
    url: `/v1/news/${id}/comments`,
  });
}

// (取消)踩/赞评论评论
export function postCommentLike(id, likes, method) {
  return request({
    url: `/v1/comment/${id}/likes`,
    method: method, // DELETE POST
    data: {
      likes: likes, // 类型：1、赞，2、踩
    },
  });
}

// 查看评论的评论
export function getCommentReply(id) {
  return request({
    url: `/v1/comment/${id}/comments`,
  });
}

// 查看评论
export function getCommentDetail(id) {
  return request({
    url: `/v1/comment/${id}`,
  });
}
