import { createRouter, createWebHistory } from "vue-router";

const Home = () => import(/* webpackChunkName: "news_comment" */"../views/home/index.vue");
const Login = () => import(/* webpackChunkName: "login_register" */"../views/login/index.vue");
const Register = () => import(/* webpackChunkName: "login_register" */"../views/register/index.vue");
const Retrieve = () => import(/* webpackChunkName: "login_register" */"../views/retrieve/index.vue");
const NewsDetail = () => import(/* webpackChunkName: "news_comment" */"../views/news-detail/index.vue");
const CommentDetail = () => import(/* webpackChunkName: "news_comment" */"../views/comment-detail/index.vue");
const My = () => import(/* webpackChunkName: "my_setting" */"../views/my/index.vue");
const Setting = () => import(/* webpackChunkName: "my_setting" */"../views/setting/index.vue");
const MyCollect = () => import(/* webpackChunkName: "my_setting" */"../views/user-collect/index.vue");
const MyNewsLike = () => import(/* webpackChunkName: "my_setting" */"../views/user-news-likes/index.vue");
const MyCommentsLike = () => import(/* webpackChunkName: "my_setting" */"../views/user-comments-like/index.vue");
const Report = () => import(/* webpackChunkName: "my_setting" */"../views/report/index.vue");
const Reset = () => import(/* webpackChunkName: "other_common" */"../views/reset/index.vue");
const Notfind = () => import(/* webpackChunkName: "other_common" */"../views/notfind/index.vue");
const Confirm = () => import(/* webpackChunkName: "other_common" */"../views/confirm/index.vue");

const routes = [
  { path: "/:pathMatch(.*)", name: "404", component: Notfind },
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/email/confirm",
    name: "confirm",
    component: Confirm,
  },
  //重置密码
  {
    path: "/password/reset",
    name: "reset",
    component: Reset,
  },
  //找回密码（发邮件）
  {
    path: "/retrieve",
    name: "retrieve",
    component: Retrieve,
  },
  {
    path: "/news",
    name: "newsDetail",
    component: NewsDetail,
  },
  {
    path: "/comment",
    name: "commentDetail",
    component: CommentDetail,
  },
  {
    path: "/my/:id",
    name: "my",
    component: My,
  },
  {
    path: "/setting",
    name: "setting",
    component: Setting,
  },
  {
    path: "/report",
    name: "report",
    component: Report,
  },
  {
    path: "/collect",
    name: "collect",
    component: MyCollect,
  },
  {
    path: "/comments-like",
    name: "commentsLike",
    component: MyCommentsLike,
  },
  {
    path: "/news-like",
    name: "newsLike",
    component: MyNewsLike,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const isLogin = localStorage.getItem("userinfo")
    ? JSON.parse(localStorage.getItem("userinfo")).access_token
    : "";
  const { name } = to;
  const isRoot =
    name === "my" ||
    name == "setting" ||
    name === "report" ||
    name === "collect" ||
    name === "commentsLike" ||
    name === "newsLike";
  isLogin || !isRoot ? next() : next({ name: "login" });
});

export default router;
