import { getUserSetting, updateUserSetting } from "../../network/api/user";
import { Toast } from "vant";

export const app = {
  namespaced: true,
  state: () => ({
    userSetting: JSON.parse(localStorage.getItem("userSetting")),
  }),
  getters: {},
  mutations: {
    CHANGE_USER_SETTING(state, userSetting) {
      state.userSetting = userSetting;
    },
    CHANGE_DARK_MODE(state, is_darkTheme) {
      state.is_darkTheme = is_darkTheme;
    },
    CHANGE_SAVE_MODE(state, is_save) {
      state.is_save = is_save;
    },
  },
  actions: {
    async getUserSetting(ctx) {
      return new Promise((resolve, reject) => {
        getUserSetting()
          .then((res) => {
            if (res.data) {
              localStorage.setItem("userSetting", JSON.stringify(res.data));
              ctx.commit("CHANGE_USER_SETTING", res.data);
              resolve(true);
            } else {
              console.log(res.msg);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateUserSetting(ctx, data) {
      return new Promise((resolve, reject) => {
        updateUserSetting(data)
          .then((res) => {
            if (res.code === 0) {
              localStorage.setItem("userSetting", JSON.stringify(data));
              ctx.commit("CHANGE_USER_SETTING", data);
              resolve(true);
            } else {
              Toast(res.msg);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
