<template>
  <router-view />
</template>

<script>
import { ref, onMounted, watch } from "vue";
import themeChanger from "./config/theme";
import store from "./store";

// 设置暗黑模式
const useSettingDarkModeEffect = () => {
  const darkTheme = ref(
    localStorage.getItem("userSetting")
      ? JSON.parse(localStorage.getItem("userSetting")).dark_mode
      : 0
  );
  const themeChange = new themeChanger();
  if (darkTheme.value == 1) {
    themeChange._addDarkTheme();
  }
};

// 设置字体大小
const useSettingFontSizeEffect = () => {
  const userFont = ref(store.state.app.userSetting.front_size);
  watch(
    () => store.state.app.userSetting.front_size,
    (newFont) => {
      userFont.value = newFont;
    }
  );
  // 原生js
  var html = document.getElementsByTagName("html")[0];
  html.style.fontSize = userFont.value + "px";
};

export default {
  setup() {
    onMounted(async () => {
      if (!localStorage.getItem("userSetting")){
        await store.dispatch("app/getUserSetting");
      }
      useSettingDarkModeEffect();
      useSettingFontSizeEffect();
    });
  },
};
</script>

<style lang="less">
.van-nav-bar__title {
  font-weight: bold !important;
}
</style>
