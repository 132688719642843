import { Toast } from "vant";
import {
  addComment,
  getCommentsList,
  getCommentDetail,
  getCommentReply,
  postCommentLike,
} from "../../network/api/comment";

export const comment = {
  namespaced: true,
  state: () => ({
    commentId: 0,
    commentsList: [],
    commentReplyList: [],
    commentDetail: {},
  }),
  getters: {},
  mutations: {
    changeCommentId(state, commentId) {
      state.commentId = commentId;
    },
    changeCommentsList(state, newData) {
      state.commentsList = newData;
    },
    changeCommentReplyList(state, commentReplyList) {
      state.commentReplyList = commentReplyList;
    },
    changeCommentDetail(state, commentDetail) {
      state.commentDetail = commentDetail;
    },
    changeCommentLike(state, { index, likes, method }) {
      if (state.commentsList.length) {
        if (method == "post" && likes === 1) {
          state.commentsList[index].like_count++;
          state.commentsList[index].likes = likes;
        } else if (method == "post" && likes === 2) {
          state.commentsList[index].dislike_count++;
          state.commentsList[index].likes = likes;
        } else if (method == "delete" && likes === 1) {
          state.commentsList[index].like_count--;
          state.commentsList[index].likes = 0;
        } else if (method == "delete" && likes === 2) {
          state.commentsList[index].dislike_count--;
          state.commentsList[index].likes = 0;
        }
      } else {
        console.log(2);
        if (method == "post" && likes === 1) {
          state.commentReplyList[index].like_count++;
          state.commentReplyList[index].likes = likes;
        } else if (method == "post" && likes === 2) {
          state.commentReplyList[index].dislike_count++;
          state.commentReplyList[index].likes = likes;
        } else if (method == "delete" && likes === 1) {
          state.commentReplyList[index].like_count--;
          state.commentReplyList[index].likes = 0;
        } else if (method == "delete" && likes === 2) {
          state.commentReplyList[index].dislike_count--;
          state.commentReplyList[index].likes = 0;
        }
      }
    },
  },
  actions: {
    // 添加评论
    async addComment(ctx, data) {
      return new Promise((resolve, reject) => {
        addComment(data)
          .then((res) => {
            if (res.code === 0) {
              Toast.success("评论成功");
              ctx.dispatch("getCommentsList", ctx.state.commentId);
              resolve(true);
            } else {
              Toast.fail(res.msg);
              reject(false);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // 查看新闻评论（列表）
    async getCommentsList(ctx, id) {
      await getCommentsList(id)
        .then((res) => {
          ctx.commit("changeCommentsList", res.data ? res.data : []);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 查看评论的评论
    async getCommentReply(ctx, id) {
      await getCommentReply(id)
        .then((res) => {
          ctx.commit("changeCommentReplyList", res.data ? res.data : []);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 查看评论详情
    async getCommentDetail(ctx, id) {
      await getCommentDetail(id)
        .then((res) => {
          ctx.commit("changeCommentDetail", res.data ? res.data : {});
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // (取消)踩/赞评论评论
    async postCommentLike(ctx, { index, id, likes, method }) {
      return new Promise((resolve, reject) => {
        postCommentLike(id, likes, method)
          .then((res) => {
            if (res.code === 0) {
              let msg = "";
              if (method == "post") {
                if (likes == 1) {
                  msg = "你赞了一下";
                } else {
                  msg = "你踩了一下";
                }
              } else {
                if (likes == 1) {
                  msg = "取消了赞";
                } else {
                  msg = "取消了踩";
                }
              }
              Toast(msg);
              ctx.commit("changeCommentLike", { index, likes, method });
              resolve(true);
            } else {
              Toast.fail(res.msg);
              reject(false);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
