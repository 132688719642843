import {
  login,
  register,
  postPasswordEmail,
  resetPassword,
  updateUserInfo,
  uploadAvatar,
  getUserUsingData,
  getUserCollectNewsList,
  getUserLikeNewsList,
  getUserLikeCommentList,
} from "../../network/api/user";
import { toRaw } from "vue";
import miment from "miment";
import { Toast } from "vant";

export const user = {
  namespaced: true,
  state: () => ({
    userinfo: {},
    userUsingData: {},
    collectNewsList: [],
    likeNewsList: [],
    likeCommentList: [],
  }),
  getters: {},
  mutations: {
    CHANGE_USER_INFO(state, userinfo) {
      state.userinfo = userinfo;
    },
    CHANGE_USER_USING_DATA(state, userUsingData) {
      state.userUsingData = userUsingData;
    },
    CHANGE_COLLECT_LIST(state, collectNewsList) {
      collectNewsList.forEach((news) => {
        news.publish_time = miment().distance(Number(news.publish_time)).format(
          "ll"
        );
      });
      state.collectNewsList = collectNewsList;
    },
    ADD_COLLECT_LIST(state, collectNewsList) {
      collectNewsList.forEach((news) => {
        news.publish_time = miment().distance(Number(news.publish_time)).format(
          "ll"
        );
      });
      state.collectNewsList = [
        ...toRaw(state.collectNewsList),
        ...collectNewsList,
      ];
    },
    CHANGE_LIKES_NEWS_LIST(state, likeNewsList) {
      likeNewsList.forEach((news) => {
        news.publish_time = miment().distance(Number(news.publish_time) * 1000).format(
          "ll"
        );
      });
      state.likeNewsList = likeNewsList;
    },
    ADD_LIKES_LIST(state, likeNewsList) {
      likeNewsList.forEach((news) => {
        news.publish_time = miment().distance(Number(news.publish_time) * 1000).format(
          "ll"
        );
      });
      state.likeNewsList = [...toRaw(state.likeNewsList), ...likeNewsList];
    },
    CHANGE_LIKES_Comment_LIST(state, likeCommentList) {
      state.likeCommentList = likeCommentList;
    },
  },
  actions: {
    login(ctx, userinfo) {
      return new Promise((resolve, reject) => {
        login(userinfo)
          .then((res) => {
            if (res.data) {
              localStorage.setItem("userinfo", JSON.stringify(res.data));
              ctx.commit("CHANGE_USER_INFO", res.data);
              resolve(true);
            } else {
              Toast.fail(res.msg);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    register(ctx, userinfo) {
      return new Promise((resolve, reject) => {
        register(userinfo)
          .then((res) => {
            if (res.code === 0) {
              resolve(true);
            } else {
              Toast.fail(res.msg);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    postPasswordEmail(ctx, usernameOrEmail) {
      return new Promise((resolve, reject) => {
        postPasswordEmail(usernameOrEmail)
          .then((res) => {
            if (res.code === 0) {
              resolve(true);
            } else {
              Toast.fail(res.msg);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    resetPassword(ctx, data) {
      return new Promise((resolve, reject) => {
        resetPassword(data)
          .then((res) => {
            if (res.data) {
              resolve(true);
            } else {
              Toast.fail("链接失效或错误");
              resolve(false);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateUserInfo(ctx, userinfo) {
      return new Promise((resolve, reject) => {
        updateUserInfo(userinfo)
          .then((res) => {
            if (res.code == 0) {
              let userinfo_change = JSON.parse(
                localStorage.getItem("userinfo")
              );
              userinfo_change.avatar = userinfo.avatar;
              userinfo_change.nick_name = userinfo.nick_name;
              localStorage.setItem("userinfo", JSON.stringify(userinfo_change));
              resolve(true);
            } else {
              Toast.fail(res.msg);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 上传头像
    uploadAvatar(ctx, file) {
      return new Promise((resolve, reject) => {
        uploadAvatar(file)
          .then((res) => {
            if (res.code === 0) {
              resolve(res.data.avatar);
            } else {
              Toast.fail(res.msg);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 用户使用数据
    getUserUsingData(ctx) {
      return new Promise((resolve, reject) => {
        getUserUsingData()
          .then((res) => {
            if (res.data) {
              localStorage.setItem("userUsingData", JSON.stringify(res.data));
              ctx.commit("CHANGE_USER_USING_DATA", res.data);
              resolve(true);
            } else {
              Toast.fail(res.msg);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 用户收藏新闻列表
    async getUserCollectNewsList(ctx) {
      await getUserCollectNewsList().then((res) => {
        ctx.commit("CHANGE_COLLECT_LIST", res.data ? res.data : []);
      });
    },
    async addUserCollectNewsList(ctx) {
      await getUserCollectNewsList().then((res) => {
        ctx.commit("ADD_COLLECT_LIST", res.data ? res.data : []);
      });
    },
    // 用户点赞新闻列表
    async getUserLikeNewsList(ctx) {
      await getUserLikeNewsList().then((res) => {
        ctx.commit("CHANGE_LIKES_NEWS_LIST", res.data ? res.data : []);
      });
    },
    async addUserLikeNewsList(ctx) {
      await getUserLikeNewsList()
        .then((res) => {
          ctx.commit("ADD_LIKES_LIST", res.data ? res.data : []);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 用户点赞评论列表
    async getUserLikeCommentList(ctx) {
      return new Promise((resolve, reject) => {
        getUserLikeCommentList()
          .then((res) => {
            ctx.commit("CHANGE_LIKES_Comment_LIST", res.data ? res.data : []);
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
