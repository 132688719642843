import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { vant } from "@/config/vant.config";
import "babel-polyfill";
import "./config/style/index.css";
import "./registerServiceWorker";
import { userSetting } from "./config/var/theme";
import { userInfo } from "./config/var/userinfo";

const app = createApp(App);
// 挂载全局变量
app.config.globalProperties.$userSetting = userSetting;
app.config.globalProperties.$userInfo = userInfo;

vant(app);
app.use(store).use(router).mount("#app");
