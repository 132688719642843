import { request } from "../request";

export function login(data) {
  return request({
    url: `/v1/login`,
    method: "post",
    data,
  });
}

export function register(data) {
  return request({
    url: `/v1/register`,
    method: "post",
    data,
  });
}

// 发送重置密码邮件
export function postPasswordEmail(data) {
  return request({
    url: `/v1/password/reset`,
    method: "post",
    data,
  });
}

// 重置密码
export function resetPassword(data) {
  return request({
    url: `/v1/password/reset`,
    method: "put",
    data,
  });
}

export function updateUserInfo(data) {
  return request({
    url: `/v1/user/info`,
    method: "put",
    data,
  });
}

export function uploadAvatar(data) {
  return request({
    url: `/v1/user/avatar/upload`,
    method: "post",
    Headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
}

export function getUserSetting() {
  return request({
    url: `/v1/user/setting`,
  });
}

export function updateUserSetting(data) {
  return request({
    url: `/v1/user/setting`,
    method: "put",
    data,
  });
}

// 获得用户使用数据
export function getUserUsingData() {
  return request({
    url: `/v1/user/report`,
  });
}

export function getUserCollectNewsList() {
  return request({
    url: `/v1/user/news/favorites?page=1&limit=10`,
  });
}

export function getUserLikeNewsList() {
  return request({
    url: `/v1/user/news/likes?page=1&limit=10`,
  });
}

export function getUserLikeCommentList() {
  return request({
    url: `/v1/user/comment/likes?page=1&limit=10`,
  });
}
