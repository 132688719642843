import { createStore } from "vuex";

import { news } from "./module/news";
import { comment } from "./module/comment";
import { user } from "./module/user";
import { app } from "./module/app";

export default createStore({
  modules: {
    news,
    comment,
    user,
    app,
  },
});
